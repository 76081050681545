<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card color="tertiary" class="eleavtion-1">
          <v-col cols="12" class="p-5">
            <div class="p-5">
              <p class="text-center">0003241 - Sobre sua Saúde</p>
            </div>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Qual sua idade ?"
              placeholder=" "
              type="number"
              outlined
            />
          </v-col>
          <v-col cols="12">
            <v-select
              label="Pratica Esportes?"
              :items="['Sim', 'Não']"
              outlined
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Quantas vezes por semana ?"
              placeholder=" "
              type="number"
              outlined
            />
          </v-col>
          <v-col cols="12">
            <v-select label="Fumante?" :items="['Sim', 'Não']" outlined />
          </v-col>

          <v-col cols="12">
            <v-btn
              class="no-text-transform"
              color="botao"
              block
            >
              <small>Confirmar</small>
            </v-btn>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          nome: "Drogasil",
          logo: "logoDrogasil.jpg",
          valor: "50,00",
        },
        {
          nome: "Drogaria SP",
          logo: "logoDrogaSP.jpg",
          valor: "40,00",
        },
        {
          nome: "Ultrafarma",
          logo: "logoUltrafarma.jpg",
          valor: "60,00",
        },
        {
          nome: "Droga Raia",
          logo: "logoDrogaraia.jpg",
          valor: "30,00",
        },
        {
          nome: "Onofre",
          logo: "logoOnofre.jpg",
          valor: "20,00",
        },
        {
          nome: "Droga Verde",
          logo: "logoDrogaVerde.jpg",
          valor: "80,00",
        },
      ],
    };
  },
};
</script>

<style></style>
